import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./containers/NotFound";

import Deliverables from "./containers/Deliverables";
import PhaseTable from "./containers/PhaseTable";
import Home from "./containers/Home";

//import ExampleTable from "./containers/ExampleTable";
import ApprovedDeliverables from "./containers/ApprovedDeliverables";
import DeliverableNotRequired from "./containers/DeliverableNotRequired";

import AWSLogin from "./containers/AWSLogin";

import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default ({ childProps }) =>
  <Switch>
    
    {/* AWS Authentication routes */ }
    <UnauthenticatedRoute path="/aws-authentication" exact component={AWSLogin} props={childProps} />

    {/* Unauthenticated Landing page */}
    <UnauthenticatedRoute path="/Home" exact component={Home} props={childProps} />

    <AuthenticatedRoute path="/Deliverables" exact component={Deliverables} props={childProps} />
    <AuthenticatedRoute path="/Approved-Deliverables" exact component={ApprovedDeliverables} props={childProps} />
    <AuthenticatedRoute path="/Deliverables-Not-Required" exact component={DeliverableNotRequired} props={childProps} />
    <AuthenticatedRoute path="/PhaseTable" exact component={PhaseTable} props={childProps} />
    
    <AuthenticatedRoute path="/" exact component={Deliverables} props={childProps} />

    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;