import React, { Component } from "react";
import { PageHeader, ListGroup, Panel, Row } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import config from "../config/EtqConfig";

import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default ({
  selectedDesignGroup,
  updateDesignGroup,

  selectedDHFName,
  updateDHFName,
  dhfNamesList,

  selectedRevisions,
  dhfRevisionList,
  updateRevisions,

  relatedDHFList,
  marketingName

}) =>

<div className="align-items-center">
        
<Row>
  <h4 className="col-md-2">Design Group </h4>
  <Select
    simpleValue
    className = "col-md-2"
    id="design-group-select"
    name="design-group-select"
    value={selectedDesignGroup}
    onChange={updateDesignGroup}
    options={config.designGroups}
  />
  
  <h4 className="col-md-2">DHF Name</h4>

  <Select
    simpleValue
    className = "col-md-3"
    id="dhf-name-select"
    name="dhf-name-select"
    value={selectedDHFName}
    onChange={updateDHFName}
    options={dhfNamesList}
  />

  
  <h4 className="col-md-1">Revisions</h4>

  <Select
    simpleValue
    multi
    className = "col-md-2"
    id="dhf-revisions-select"
    name="dhf-revisions-select"
    value={selectedRevisions}
    options={dhfRevisionList}
    onChange={updateRevisions}
    removeSelected={true}
    closeOnSelect={false}
  />
</Row>
<br></br>
<Row>
  <h4 className="col-md-2">Marketing Name</h4>
  <Select
    simpleValue
    multi
    className = "col-md-10"
    id="dhf-marketing-name"
    name="dhf-marketing-name"
    value={marketingName}
  />
  
</Row>
<br></br>
<Row>
  <h4 className="col-md-2">Related DHF</h4>

  <Select
    simpleValue
    multi
    className = "col-md-10"
    id="dhf-related-dhf"
    name="dhf-related-dhf"
    value= {relatedDHFList}
  /> 

  
</Row>    
<Row>
  <br></br>
  <br></br>
  <br></br>
</Row>


</div>