import React, { Component, } from "react";
import { Button } from "react-bootstrap";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../config/AzureConfig";

import "./Home.css";

export default class Home extends Component {

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);

  }

  handleClick = async () => {
    const msalInstance = new PublicClientApplication(msalConfig);

    await msalInstance.loginPopup({
      scopes: ["User.Read"]
    })
      .then(function (loginResponse) {

        // set token
        localStorage.setItem('azure-token', loginResponse.idToken);

      }).catch(function (error) {
        //login failure
        console.log(error);
      });

    this.props.history.push('/aws-authentication');
  }

  render() {
    return (
      <div className="Home">
        <div className="lander">
          <h1>DHF Navigator</h1>
          <p>A navigational assistant for exploring design history files.</p>
          <br></br>
          <Button
            onClick={this.handleClick}>
            Log In
          </Button>
        </div>
        <div>
        </div>
        <footer className="navbar-fixed-bottom">
          <div className="container">
            <div className="row">
              <p>Copyright &copy; 2018 Varex Imaging Design Team</p>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}