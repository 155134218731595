export default {
  dhfPhases : [
    'Phase 1',
    'Phase 2',
    'Phase 3',
    'Phase 4',
    'Phase 5',
    'Design Change'
  ],
  designGroups : [
    {value: 'Detectors', label: 'Detectors'},
    {value: 'Workstations', label: 'Workstations'},
    {value: 'Sources', label: 'Sources'}
  ]
};