
import { API } from "aws-amplify";
import config from "../config/EtqConfig";

export function GetDHFJson(dhfName){
  let myInit = {
    queryStringParameters: { 
      "dhf_name": dhfName,
    }
  }
  return API.get("deliverables","/deliverables", myInit).then(response => {
    return response;
  }).catch(error => {
      console.log(error.response)
  });
}

export function GetPhaseTable(){
  return API.get("phase-table","/phase-table").then(response => {
    
    var phaseTable = {
      'dhfNames' : response,
      'lastRefresh' : new Date()
    }
    
    return phaseTable;
  }).catch(error => {
      console.log(error.response)
  });
}

export function GetDhfNamesByDesignGroup(designGroup){
  let myInit = {
    queryStringParameters: { 
      "design_group_description": designGroup,
    }
  }
  return API.get("dhfnamesbydesigngroup","/dhfnamesbydesigngroup", myInit).then(response => {
    return formatResponse(response)
  }).catch(error => {
      console.log(error.response)
  });
}

export function GetDhfRevisionsByName(dhfName){
  let myInit = {
    queryStringParameters: { 
      "dhf_name": dhfName,
    }
  }
  return API.get("dhfrevisionsbyname","/dhfrevisionsbyname", myInit).then(response => {
    return formatResponse(response)
  }).catch(error => {
      console.log(error.response)
  });
}

export function GetlinkedDHFByName(dhfName){
  let myInit = {
    queryStringParameters: { 
      "dhf_name": dhfName,
    }
  }
  return API.get("linkedDHFByName","/linkedDHFByName", myInit).then(response => {
    return formatResponse(response)
  }).catch(error => {
      console.log(error.response)
  });
}
export function GetphaseDueDateByName(dhfName){
  let myInit = {
    queryStringParameters: { 
      "dhf_name": dhfName,
    }
  }
  return API.get("phaseDueDate","/phaseDueDate", myInit).then(response => {
    return formatResponse(response)
  }).catch(error => {
      console.log(error.response)
  });
}
export function GetmarketingNameByName(dhfName){
  let myInit = {
    queryStringParameters: { 
      "dhf_name": dhfName,
    }
  }
  return API.get("marketingName","/marketingName", myInit).then(response => {
    return formatResponse(response)
  }).catch(error => {
      console.log(error.response)
  });
}
export function formatResponse(response){

  if(response.Records){
    response.Records.forEach(document => {
      for (var i=0; i<document.Columns.length; i++) {
        document[document.Columns[i].name] = document.Columns[i].value;
      }
      delete document.Columns;
    });

    return response.Records;
  }

  return null;
}
