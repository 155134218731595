import React, { Component } from "react";
import { PageHeader, ListGroup, Panel, Row, Col, Button } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";

import HeaderWithRefresh from "../components/HeaderWithRefresh"

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { ValidationAndVerificationHeader, CommaArrayFormatter, ImbeddedArrayFormatter,  PhaseTableStatusFormatting, PhaseTableLabelFormatting } from '../utilities/TableFormatters';
import { GetPhaseTable } from '../utilities/EtqApi';

import config from "../config/EtqConfig";

import Select from 'react-select';
import 'react-select/dist/react-select.css';
import "./PhaseTable.css";

export default class PhaseTable extends Component {
  constructor(props) {
    super(props);

    this.reloadPhaseTable = this.reloadPhaseTable.bind(this)

    this.state = {
      isLoading: true,
      isBuildingPhaseTable: false,
      phaseTable: {
        'dhfNames' : [],
        'lastRefresh' : new Date()
      }
    };
  }


  columns = [{
    dataField: "DHF_DHF_NAME",
    text: 'Project',
    sort: true
  }, {
    dataField: "PROGRAM_MANAGERS",
    text: 'Program Manager',
    filter: textFilter(),
    formatter: CommaArrayFormatter
  }, {
    dataField: "DHF_DESIGN_GROUP",
    text: 'Design Group',
    filter: textFilter()
  }, {
    dataField: "Phase 1",
    text: 'Phase 1',
    formatter: PhaseTableLabelFormatting,
  }, {
    dataField: "Phase 2",
    text: 'Phase 2',
    formatter: PhaseTableLabelFormatting,
  }, {
    dataField: "Phase 3",
    text: 'Phase 3',
    formatter: PhaseTableLabelFormatting,
  }, {
    dataField: "Phase 4",
    text: 'Phase 4',
    formatter: PhaseTableLabelFormatting,
    //headerFormatter: ValidationAndVerificationHeader
  }, {
    dataField: "Phase 5",
    text: 'Phase 5',
    formatter: PhaseTableLabelFormatting,
  }];

  defaultSorted = [{
    dataField: 'DHF_DHF_NAME',
    order: 'asc'
  }];

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    const cachedDeliverablePhaseMap = localStorage.getItem('DELIVERABLE-PHASE-MAP');
    if (cachedDeliverablePhaseMap) {
      this.setState({ phaseTable: JSON.parse(cachedDeliverablePhaseMap) });
    } else {
      this.reloadPhaseTable();
    }

  }

  async reloadPhaseTable() {
    this.setState({ isBuildingPhaseTable: true });
    try {

      //Get DHF Names
      var phaseTable = await GetPhaseTable();

      //Get Phase Statuses
      this.setState({phaseTable: phaseTable});

      localStorage.setItem('DELIVERABLE-PHASE-MAP', JSON.stringify(phaseTable));
      
      this.setState({ isBuildingPhaseTable: false });

    } catch (e) {
      alert(e);
    }
  }

  render() {
        
    return (
      <div className="Dashboard">
        
        <HeaderWithRefresh 
          title = "Project Phase Table"
          selectedDHFName = "Phase Table"
          handleUpdateButtonClick = {this.reloadPhaseTable}
          isUpdatingDHF = {this.state.isBuildingPhaseTable}
          lastRefresh = {this.state.phaseTable.lastRefresh}
            />

        <BootstrapTable
                  keyField='id'
                  data={this.state.phaseTable.dhfNames}
                  columns={this.columns}
                  defaultSorted={ this.defaultSorted } 
                  filter={filterFactory()}/>
      </div>
    );
  }
}