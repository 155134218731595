import React, { Component } from "react";
import { PageHeader, ListGroup, Panel, Row, Col } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { CommaArrayFormatter,DueDateClassFormatting, ImbeddedArrayFormatter, AttachmentLinkFormatter, LinkedDeliverableFormatting, PhaseStatusClassFormatting, DateFormatter, DocumentNumberLinkFormatter, PhaseStatusFormatting } from '../utilities/TableFormatters'

import LoaderButton from "../components/LoaderButton";

import HeaderWithRefresh from "../components/HeaderWithRefresh"

import Moment from 'react-moment';

import PhaseDeliverableTable from "../components/PhaseDeliverableTable";
import DeliverableFilters from "../components/DeliverableFilters";


import { GetDHF } from "../store/DHF";

import { GetDhfRevisionsByName, GetDhfNamesByDesignGroup, GetDeliverablesPhaseDictionary } from '../utilities/EtqApi'


import config from "../config/EtqConfig";

import Select from 'react-select';
import 'react-select/dist/react-select.css';
import "./ExampleTable.css";

export default class Deliverables extends Component {
  constructor(props) {
    super(props);


    this.updateDesignGroup = this.updateDesignGroup.bind(this);
    this.updateDHFName = this.updateDHFName.bind(this);
    this.updateRevisions = this.updateRevisions.bind(this);
    this.updatePhases = this.updatePhases.bind(this);
    this.getDHFStore = this.getDHFStore.bind(this);
    this.handleUpdateButtonClick = this.handleUpdateButtonClick.bind(this);
    this.filterRevisions = this.filterRevisions.bind(this);
    
    this.state = {
      isLoading: true,
      dhfNamesList: [],
      selectedDhfId: '',
      selectedDesignGroup: '',
      selectedDHFName: '',
      deliverablePhaseMap: {},
      dhfRecordList: [],
      dhfRevisionList: [],
      selectedRevisions: '',
      filteredDeliverablePhaseMap: {},
      selectedPhases: [],
      isUpdatingDHF: false,
      dhf: {},
      relatedDHFList: [],
      completionDateMap: {},
      marketingName: [],
	  
    };
  }

  columns = [{
    dataField: "DELIVERABLE_DELIVERABLE_TITLE",
    text: 'Deliverable Title'
  }, {
    dataField: "DELIVERABLE_RECORD_NUMBER",
    text: 'Deliverable Record Number',
    formatter: DocumentNumberLinkFormatter,
    filter: textFilter(),
  }, {
    dataField: "DELIVERABLE_REVISION_NUMBER",
    text: 'Revision'
  }, {
    dataField: "ETQ_DELIVERABLE_TYPE_PROFIL",
    text: 'Deliverable Profile Name',
    filter: textFilter(),
  }, {
    dataField: "ASSIGNED_USERS",
    text: 'Currently Assigned to',
    filter: textFilter(),
    formatter: CommaArrayFormatter
  }, {
    dataField: "AUTHORS",
    text: 'Document Owner',
    filter: textFilter(),
    formatter: CommaArrayFormatter
  }, {
    dataField: "DELIVERABLE_INITIAL_DUE_DATE",
    text: 'Target Due Date',
    formatter: DateFormatter,
  }, {
    dataField: "DELIVERABLE_CURRENT_DUE_DATE",
    text: 'Current Due Date',
    formatter: DateFormatter,
    classes: DueDateClassFormatting,
  }, {
    dataField: "DELIVERABLE_ETQ$COMPLETED_DATE",
    text: 'Date Complete',
    formatter: DateFormatter,
  }, {
    dataField: "DELIVERABLE_CURRENT_PHASE_SETTING_DISPLAY_NAME",
    text: 'Status',
    classes: PhaseStatusClassFormatting,
    formatter: PhaseStatusFormatting,
    sort: true,
  }];

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
  }

  async updateDesignGroup(newDesignGroup) {
    this.setState({ selectedDesignGroup: newDesignGroup });

    if (newDesignGroup) {
      var dhfNamesList = await GetDhfNamesByDesignGroup(newDesignGroup)
      dhfNamesList = dhfNamesList.map(item => ({
        label: item.DHF_DHF_NAME,
        value: item.DHF_DHF_NAME
      }));
     
      this.setState({ dhfNamesList: dhfNamesList });
    } else {
      this.setState({ selectedDHFName: '' });
      this.setState({ dhfRevisionList: [] });
      this.setState({ dhfNamesList: [] });
      this.setState({ filteredDeliverablePhaseMap: {} });
      this.setState({ relatedDHFList: [] });
      this.setState({ completionDateMap: {} });
      this.setState({ marketingName: [] });
    }
  }

  async getDHFStore(getFromCache) {

    this.setState({ isUpdatingDHF: true });
    var dhf = await GetDHF(this.state.selectedDHFName, getFromCache);
    var deliverablePhaseMap = dhf.deliverablesPhaseDictionary;
    this.setState({ deliverablePhaseMap: deliverablePhaseMap });
    this.setState({ dhf: dhf });

    var dhfRevisionList = dhf.revisionsList
    dhfRevisionList = dhfRevisionList.map(item => ({
      label: item,
      value: item
    }));

    var relatedDHFList = dhf.linkedDHFList;
    relatedDHFList = relatedDHFList.map(item => ({
      label: item,
      value: item
    }));
    
    var marketingName = dhf.marketingName;
    marketingName = marketingName.map(item => ({
      label: item,
      value: item  
    }));
	  var completionDateMap = dhf.deliverablesPhaseCompletionDate;
    this.setState({ completionDateMap: completionDateMap });	
    this.setState({ relatedDHFList: relatedDHFList });
    this.setState({ dhfRevisionList: dhfRevisionList });
    this.setState({ marketingName: marketingName });
    this.setState({ isUpdatingDHF: false });
    this.filterRevisions();
	  this.filterPhases();
  }

  handleUpdateButtonClick() {
    this.getDHFStore(false);
  }

  updateDHFName(newDHFName) {
    if (newDHFName) {
      this.setState({ selectedDHFName: newDHFName }, function () {
        //After dhf name is state, get the object
        this.getDHFStore(true);
      });

    } else {
      this.setState({ selectedDHFName: '' });
      this.setState({ dhfRevisionList: [] });
      this.setState({ filteredDeliverablePhaseMap: {} });
      this.setState({ relatedDHFList: [] });
      this.setState({ completionDateMap: {}});
      this.setState({ marketingName: [] });
    }
  }

  filterRevisions() {
    var revisions = this.state.selectedRevisions;
    if (revisions) {
      var selectedRevisionsArray = revisions.split(",");
      var unfilteredDeliverablePhaseMap = this.state.deliverablePhaseMap;
      var filteredDeliverablePhaseMap = {};

      for (var key in unfilteredDeliverablePhaseMap) {
        if (unfilteredDeliverablePhaseMap.hasOwnProperty(key)) {
          var filteredArray = []
          for (var selectedRevisionIndex = 0; selectedRevisionIndex < selectedRevisionsArray.length; selectedRevisionIndex++) {
            var newArray = unfilteredDeliverablePhaseMap[key].filter(function (deliverable) {
              return deliverable.MAJOR_MINOR_VERSION == selectedRevisionsArray[selectedRevisionIndex]
            });

            Array.prototype.push.apply(filteredArray, newArray);
          }
          filteredDeliverablePhaseMap[key] = filteredArray
        }
      }
      this.setState({ filteredDeliverablePhaseMap: filteredDeliverablePhaseMap })
    }
  }
  
  filterPhases() {
    var phases = this.state.selectedPhases;
    if (phases) {
      var unfilteredDateMap = this.state.completionDateMap;
      var filteredDateMap = {};

      for (var key in unfilteredDateMap) {
        if (unfilteredDateMap.hasOwnProperty(key)) {
          var filteredArray = []
          var newArray = unfilteredDateMap[key];
          Array.prototype.push.apply(filteredArray, newArray);
          filteredDateMap[key] = filteredArray
        }
      }
      this.setState({ filteredDateMap: filteredDateMap })
    }
  }

  async updateRevisions(selectedRevisions) {
    if (selectedRevisions) {
      this.setState({ selectedRevisions: selectedRevisions }, this.filterRevisions);
    }
  }

  async updatePhases(selectedPhases) {
    var selectedPhasesArray = selectedPhases.split(",");
    this.setState({ selectedPhases: selectedPhasesArray })
  }

  render() {
    return (
      <div className="Dashboard">

        <HeaderWithRefresh
          title="View DHF Deliverables/Tasks by Phase"
          selectedDHFName={this.state.selectedDHFName}
          handleUpdateButtonClick={this.handleUpdateButtonClick}
          isUpdatingDHF={this.state.isUpdatingDHF}
          lastRefresh={this.state.dhf.lastRefresh}
        />

        <br />

        <DeliverableFilters
          selectedDesignGroup={this.state.selectedDesignGroup}
          updateDesignGroup={this.updateDesignGroup}

          selectedDHFName={this.state.selectedDHFName}
          updateDHFName={this.updateDHFName}
          dhfNamesList={this.state.dhfNamesList}

          selectedRevisions={this.state.selectedRevisions}
          dhfRevisionList={this.state.dhfRevisionList}
          updateRevisions={this.updateRevisions}

          relatedDHFList={this.state.relatedDHFList}
          marketingName ={this.state.marketingName}
        />

        <br />
        <PhaseDeliverableTable
          selectedPhases={this.state.selectedPhases}
          filteredDeliverablePhaseMap={this.state.filteredDeliverablePhaseMap}
          columns={this.columns}
          updatePhases={this.updatePhases}
		      completionDateMap = {this.state.filteredDateMap}
        />
      </div>
    );
  }
}