import { GetDHFJson } from '../utilities/EtqApi'


export function GetDHF(dhfName, getFromCache) {
    
    if(getFromCache){
        const cachedDhf = localStorage.getItem(dhfName);    
        if (cachedDhf) {
            return JSON.parse(cachedDhf);
        } else {
            return getDHFFromWeb (dhfName);
        }

    } else {
       return getDHFFromWeb (dhfName);
    }
  }
  
export async function getDHFFromWeb (dhfName){
    var dhfJson = await GetDHFJson(dhfName);
    localStorage.setItem(dhfName, JSON.stringify(dhfJson));
    return dhfJson;
}
  