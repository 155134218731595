import React, { Component } from "react";
import { Button, Table } from "react-bootstrap";


export function DateFormatter(cell, row, rowIndex, formatExtraData) {
  if (cell) {
    var a=cell.split(" ");
    var d=a[0].split("-");
    var t=a[1].split(":");
    var formatDate = new Date(d[0],(d[1]-1),d[2],t[0],t[1],t[2]);
    return (formatDate.getMonth() + 1) + '/' + formatDate.getDate() + '/' + formatDate.getFullYear();
  }
  return '';
}

export function DocumentNumberLinkFormatter(cell, row, rowIndex, formatExtraData) {  
  if (cell){
    var stage = process.env.REACT_APP_STAGE;
    var etqNumber = row.DELIVERABLE_ID;
    if(stage === 'prod'){
      return <a target="_blank" href={"https://varex.etq.com/prod/reliance?ETQ$CMD=CMD_OPEN_DOC&ETQ$APPLICATION_NAME=ETQ_DESIGN_HISTORY_FILE_DHF&ETQ$FORM_NAME=ETQ_DHF_DELIVERABLE&ETQ$KEY_NAME=DELIVERABLE_ID&ETQ$KEY_VALUE=" + etqNumber}>{cell} <i className="fa fa-external-link"></i></a>  
    } else{
      return <a target="_blank" href={"https://varex.etq.com:8443/" + stage + "/reliance?ETQ$CMD=CMD_OPEN_DOC&ETQ$APPLICATION_NAME=ETQ_DESIGN_HISTORY_FILE_DHF&ETQ$FORM_NAME=ETQ_DHF_DELIVERABLE&ETQ$KEY_NAME=DELIVERABLE_ID&ETQ$KEY_VALUE=" + etqNumber}>{cell} <i className="fa fa-external-link"></i></a>
    }    
  }  
  return <p>NOT YET CREATED!</p>
}

export function PhaseStatusClassFormatting(cell, row, rowIndex, formatExtraData) {

  if (cell === 'Complete') return 'status-complete';
  if (cell === 'Approval'){
    var DaysUntilDue = row.DAYS_OVERDUE;
    if(DaysUntilDue < 0) return 'status-approval';
  } 
  if (cell === 'Draft') return 'status-draft';

}

export function PhaseStatusFormatting(cell, row, rowIndex, formatExtraData) {

  if (cell === 'Complete') return 'Complete';
  if (cell === 'Approval') return 'Routing for Approval';
  if (cell === 'Draft') return 'Draft';
  if (cell === 'Void') return 'VOID';

}

export function DueDateClassFormatting(cell, row, rowIndex, formatExtraData) {
  var Status= row.DELIVERABLE_CURRENT_PHASE_SETTING_DISPLAY_NAME;
  var DaysUntilDue = row.DAYS_OVERDUE;
  if(Status==='Approval' || Status==='Draft'){
    if(DaysUntilDue < 0) return 'due-date';  
  }
}

export function LinkedDeliverableFormatting(cell, row, rowIndex, formatExtraData) {

  if (cell === '1') return 'Yes';
  return 'No';

}

export function PhaseTableLabelFormatting(cell, row, rowIndex, formatExtraData) {
  var style = "default"
  if (cell == 'COMPLETE')
    style = "success"
  if (cell == 'IN_PROGRESS')
    style = "warning"

  return <Button bsStyle={style}> </Button>;

}

export function AttachmentLinkFormatter(cell, row, rowIndex, formatExtraData) {

  if (!cell)
    return

  //encode spaces
  var encodedName = cell.replace(/ /g, '+');
  var etqNumber = row.DELIVERABLE_ID;
  var stage = process.env.REACT_APP_STAGE;

  if(stage === 'prod'){
    return <a target="_blank" href={"https://varex.etq.com/prod/reliance?ETQ$CMD=CMD_OPEN_ATTACHMENT&ETQ$SOURCE_FIELD_NAME=ETQ_DHF_DELIVERABLE_ATTACHMENTS&ETQ$APPLICATION_NAME=ETQ_DESIGN_HISTORY_FILE_DHF&ETQ$FORM_NAME=ETQ_DHF_DELIVERABLE&ETQ$KEY_NAME=DELIVERABLE_ID&ETQ$KEY_VALUE=" + etqNumber + "&ETQ$PARENT_DIALOG_NAME=null&ETQ$SUBFORM_NAME=&ETQ$RECORD_ID=null&ETQ$FILE_NAME=" + encodedName + "&ETQ$APPLICATION_NAME=ETQ_DESIGN_HISTORY_FILE_DHF"}>{cell} <i className="fa fa-external-link"></i></a>
  }else{
    return <a target="_blank" href={"https://varex.etq.com:8443/" + stage + "/reliance?ETQ$CMD=CMD_OPEN_ATTACHMENT&ETQ$SOURCE_FIELD_NAME=ETQ_DHF_DELIVERABLE_ATTACHMENTS&ETQ$APPLICATION_NAME=ETQ_DESIGN_HISTORY_FILE_DHF&ETQ$FORM_NAME=ETQ_DHF_DELIVERABLE&ETQ$KEY_NAME=DELIVERABLE_ID&ETQ$KEY_VALUE=" + etqNumber + "&ETQ$PARENT_DIALOG_NAME=null&ETQ$SUBFORM_NAME=&ETQ$RECORD_ID=null&ETQ$FILE_NAME=" + encodedName + "&ETQ$APPLICATION_NAME=ETQ_DESIGN_HISTORY_FILE_DHF"}>{cell} <i className="fa fa-external-link"></i></a>
  }  
  //return <a target="_blank" href={"https://varex.etq.com:8443/dev/reliance?ETQ$CMD=CMD_OPEN_ATTACHMENT&ETQ$SOURCE_FIELD_NAME=ETQ_DHF_DELIVERABLE_ATTACHMENTS&ETQ$APPLICATION_NAME=ETQ_DESIGN_HISTORY_FILE_DHF&ETQ$FORM_NAME=ETQ_DHF_DELIVERABLE&ETQ$KEY_NAME=DELIVERABLE_ID&ETQ$KEY_VALUE=" + etqNumber + "&ETQ$PARENT_DIALOG_NAME=null&ETQ$SUBFORM_NAME=&ETQ$RECORD_ID=null&ETQ$FILE_NAME=" + encodedName + "&ETQ$APPLICATION_NAME=ETQ_DESIGN_HISTORY_FILE_DHF"}>{cell} <i className="fa fa-external-link"></i></a>

}

export function ImbeddedArrayFormatter(cell, row, rowIndex, formatExtraData) {

  if (!cell)
    return

  return <ul styles="list-style-type:none">
      {cell.map((item) => {
        return (
            <li>{item}</li>
        )

      })}
  </ul>

}

export function CommaArrayFormatter(cell, row, rowIndex, formatExtraData) {

  if (!cell)
    return

  return <p>{cell.join(', ')}  </p>

}

export function ValidationAndVerificationHeader(column, colIndex) {
  return (<div>
    <h5><strong>Phase 4</strong></h5>
    </div>
  );
}

