import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { azure_issuer } from "../config/AzureConfig";

export default class AWSLogin extends Component {
  constructor(props) {
    super();
    this.state = { ...props };

    this.parseJwt = this.parseJwt.bind(this);
  }

  componentWillMount = async event => {

    //Determine login technique. for now defaults to OKTA, however if I want this to be dynamic I will add some code here
    //Get token from local storage

    try {

      const storedToken = localStorage.getItem('azure-token');
      let parsedIdToken = this.parseJwt(storedToken);

      //set parameters
      var issuer = azure_issuer;
      var expires = parsedIdToken.exp
      var user = {
        email: parsedIdToken.preferred_username,
        name: parsedIdToken.name,
      }

      await Auth.federatedSignIn(
        // Initiate federated sign-in with identity provider 
        issuer,
        {
          token: storedToken,
          expires_at: expires * 1000
        },
        // a user/claims object
        user
      );

      this.props.userHasAuthenticated(true);
      this.props.history.push("/Deliverables");

    } catch (e) {
      console.log(e);
      localStorage.removeItem('azure-token')
      this.props.history.push("/Home");
    }
  }

  parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  render() {
    return (<section>Redirecting...</section>);
  }
}
