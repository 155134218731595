import React, { Component } from "react";
import { PageHeader, ListGroup, Panel, Row } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import config from "../config/EtqConfig";

import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default ({
  selectedPhases,
  filteredDeliverablePhaseMap,
  columns,
  updatePhases,
  completionDateMap
}) =>
  <div>
    <Row>
      <h4>Select Phases to Display:</h4>
      <Select
        simpleValue
        multi
        id="phase-select"
        name="phase-select"
        value={selectedPhases}
        options={config.dhfPhases.map(item => ({
          label: item,
          value: item
        }))}
        onChange={updatePhases}
        removeSelected={true}
        closeOnSelect={false}
      />
    </Row>
    <br/>
    <Row>

      {selectedPhases.map((phase) => {
        return (
          <Panel id={"panel_" + phase} defaultExpanded>
            <Panel.Heading>
              <Panel.Title toggle componentClass="h3">{phase}
              <Panel.Title toggle componentClass="h3" className="pull-right">Planned Phase Completion Date: {completionDateMap[phase]}</Panel.Title>
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body>
              {filteredDeliverablePhaseMap[phase] &&
                <BootstrapTable
                  keyField='id'
                  data={filteredDeliverablePhaseMap[phase]}
                  columns={columns}
                  filter={filterFactory()} />
              }
            </Panel.Body>
          </Panel>
        )
      })}

    </Row>
</div>
