import React, { Component } from "react";
import { PageHeader, Row, Col } from "react-bootstrap";
import Moment from 'react-moment';
import LoaderButton from "./LoaderButton"


export default ({
  title,
  selectedDHFName,

  handleUpdateButtonClick,
  isUpdatingDHF,
  lastRefresh,

}) =>

  <div>
    <Row>
      <Col xs={9}>
        <PageHeader>{title}</PageHeader>
      </Col>
      <Col xs={3}>
        <br></br>
        <br></br>
        {selectedDHFName != '' &&
          <div>
            <LoaderButton
              onClick={handleUpdateButtonClick}
              block
              bsSize="large"
              type="button"
              isLoading={isUpdatingDHF}
              text={"Reload " + selectedDHFName}
              loadingText="Loading Data" />
            <p className="text-center text-muted"> last refreshed&nbsp;<Moment fromNow date={lastRefresh} /> </p>
          </div>
        }

      </Col>
    </Row>

    <br />
    <br />

  </div>