import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem, PageHeader } from "react-bootstrap";
import Routes from "./Routes";
import { LinkContainer } from "react-router-bootstrap";
import { Auth, Cache } from "aws-amplify";

import IdleTimer from 'react-idle-timer';
import "./App.css";

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      timeout: 3600000,
    };
  }

  async componentDidMount() {
    
    try {
      var currentCredentials = await Auth.currentCredentials()
      if (!currentCredentials.expired) {
        this.userHasAuthenticated(true);
      }
    }
    catch (e) {
    }

    this.setState({ isAuthenticating: false });
  }

  userIsAuthenticating = authenticating => {
    this.setState({ isAuthenticating: authenticating });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.props.history.push("/Home");
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      userIsAuthenticating: this.userIsAuthenticating,
    };

    return (
      !this.state.isAuthenticating &&
      <div className="App container">

        <IdleTimer
          ref="idleTimer"
          element={document}
          idleAction={this.handleLogout}
          timeout={this.state.timeout}>

          <div id="authenticated">
            <Navbar fluid collapseOnSelect>
              {this.state.isAuthenticated
                ?
                <Nav>
                  <NavItem href="/Deliverables"> DHF Deliverables </NavItem>
                  <NavItem href="/Approved-Deliverables"> Approved Deliverables </NavItem>
                  <NavItem href="/Deliverables-Not-Required"> Deliverables Not Required </NavItem>
                  <NavItem href="/PhaseTable">Project Phase Table</NavItem>
                </Nav>
                :
                <Navbar.Brand>DHF Navigator</Navbar.Brand>
              }

              <Navbar.Collapse>
                <Nav pullRight>
                  {this.state.isAuthenticated
                    ? <NavItem onClick={this.handleLogout}>Logout</NavItem>
                    : <Fragment>
                    </Fragment>
                  }
                </Nav>
              </Navbar.Collapse>

            </Navbar>

            {/* Routes part ofis authenticated */}
            <Routes childProps={childProps} />
          </div>

        </IdleTimer>
      </div>

    );
  }
}

export default withRouter(App);