const dev = {apiGateway: {
  REGION: "us-east-1",
  URL: "https://tkveg760ri.execute-api.us-east-1.amazonaws.com/dev" 
},
cognito: {
  REGION: "us-east-1",
  IDENTITY_POOL_ID: "us-east-1:d3a26d64-7a7b-4d8f-98ea-59aaace27c14"
}
};

const val = {apiGateway: {
  REGION: "us-east-1",
  URL: "https://a8qr9gi289.execute-api.us-east-1.amazonaws.com/val"
},
cognito: {
  REGION: "us-east-1",
  IDENTITY_POOL_ID: "us-east-1:b0b59a62-2d0c-476e-960b-ef34292ec008"
}
};

const prod = {apiGateway: {
  REGION: "us-east-1",
  URL: "https://affeufeseg.execute-api.us-east-1.amazonaws.com/prod" //+ process.env.REACT_APP_STAGE
},
cognito: {
  REGION: "us-east-1",
  IDENTITY_POOL_ID: "us-east-1:f0b08fd2-4707-4c07-8fab-95b721650f29"
}
};


const config = (process.env.REACT_APP_STAGE === 'prod') ? prod
: (process.env.REACT_APP_STAGE === 'val') ? val
: dev;

export default {
... config
}


/*export default {
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://tkveg760ri.execute-api.us-east-1.amazonaws.com/" + process.env.REACT_APP_STAGE
    },
    cognito: {
      REGION: "us-east-1",
      IDENTITY_POOL_ID: "us-east-1:d3a26d64-7a7b-4d8f-98ea-59aaace27c14"
    }
  };*/